import React, { useState } from 'react';
import './ExpenseForm.css';
const ExpenseForm = () => {
    const [formData, setFormData] = useState({
        amount: '',
        description: '',
        category: '',
        date: '',
        file: null // Pentru fișier
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            file: e.target.files[0]
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const data = new FormData();
        data.append('amount', formData.amount);
        data.append('description', formData.description);
        data.append('category', formData.category);
        data.append('date', formData.date);
        data.append('file', formData.file);

        try {
            const response = await fetch('https://expenses.prodek.xyz:3001/api/expenses', {
                method: 'POST',
                body: data
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const responseData = await response.json();
            console.log('Expense added successfully:', responseData);
        } catch (error) {
            console.error('Error while adding expense:', error);
        }
    };

    return (<form onSubmit={handleSubmit} className="expense-form">
      <h2>Add New Expense</h2>
      <div className="form-group">
        <label htmlFor="amount">Amount:</label>
        <input
          type="number"
          id="amount"
          name="amount"
          value={formData.amount}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="description">Description:</label>
        <textarea
          id="description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="category">Category:</label>
        <input
          type="text"
          id="category"
          name="category"
          value={formData.category}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="date">Date:</label>
        <input
          type="date"
          id="date"
          name="date"
          value={formData.date}
          onChange={handleChange}
          required
        />
      </div>
      <div className="form-group">
        <label htmlFor="file">Receipt/File:</label>
        <input
          type="file"
          id="file"
          name="file"
          onChange={handleFileChange}
        />
      </div>
      <button type="submit" className="submit-btn">Add Expense</button>
    </form>
    );
};

export default ExpenseForm;
